.header {
  display: flex;
  align-items: center;
  /* background-color: #fcbd2c; */
  background-color: #FFFDF1;
  height: 10vh;
  border-bottom: solid #f8f0db 1px;
}

.sign-up-container {
  display: flex;
  align-content: center;
  justify-content: center;

 }

.sign-up-button {
  width: 100px;
  height: auto;
  background-size: cover;
  cursor: pointer;
  padding: 10px;

}

.logspot-logo {
  width: 150px;
  height: auto; 
  padding: 10px;
}

.header-toolbar, .login-toolbar {
  font-size: x-large;
  /* font-weight:bolder; */

}


.menu-icon {
  display: none; 
  flex-direction: column;
  cursor: pointer;
  z-index: 2;
}

.menu-line {
  width: 25px;
  height: 3px;
  background-color: #422513;
  margin: 3px 0;
  
}

/* Dropdown menu styles */

.menu-dropdown {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  text-align: center;
  /* background-color:#fcbd2c; */
  background-color: #FFFDF1;
  font-size: medium;
  /* font-weight:540; */
  margin: auto;
  width: 1840px;
}

.menu-dropdown a {
  display: block;
  /* padding: 10px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: #fcbd2c; */
  background-color: #FFFDF1;
  text-decoration: none;
  
}

.menu-icon.open .menu-line:nth-child(1) {
  transform: rotate(45deg) translate(4px, 4px);
}

.menu-icon.open .menu-line:nth-child(2) {
  opacity: 0;
}

.menu-icon.open .menu-line:nth-child(3) {
  transform: rotate(-45deg) translate(9px, -9px);
}




@media (max-width: 900px) {

.header-toolbar, .login-toolbar {
  font-size: x-large;
}

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    
  }

  .menu-icon {
    display: flex;
    padding-right: 20px;
    padding-top: 20px;
  }

  .menu-dropdown {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    max-width: 100%;
    height: auto; 
    overflow: hidden;
    z-index: 1;
    display: flex;
    transform: translateY(-100%);
    transition: transform 1s ease-in-out;
  }

  .menu-dropdown.open {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* height: auto; */
    transform: translateY(0); 

  }

  .logspot-logo-new {
    width: 150px;
    height: auto; 
    padding: 10px;
  }


  .menu-icon-container {
    display: grid;
    grid-template-columns: 29fr 1fr;
   


  }

 
}

