.about-us-container {
  display: grid;
  grid-template-columns: 1.1fr 2fr;
  }

  .top-text{
    font-weight: bolder;
    font-size: 35px;
    padding-bottom: 20px;
    padding-top: 50px;    
}
  
  .our-story {
    max-width: 425px;
    padding-left: 10px;

    
  }
  
  
  .about-us-info {
      flex: 2; /* Expand to fill available space */
  }
  
  .map-wrapper {
      flex: 2; /* Expand to fill available space */
      padding-bottom: 50px;
  }
  

  
  
  .description-names {
    font-size: x-large;
    font-weight: bold;
    padding: 5px;
    
  }
  
  
  .rammstein {
      display: grid;
      grid-template-rows: 2fr 0.5fr 2fr;
      align-items: top;
      justify-items: center;
  }
  
  .my-picture {
      max-width: 400px;
      padding-top: 30px;
  
  }
  
  .contact-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  
  .contact-item img {
    margin-right: 10px; 
  }
  
  
  
  .Viber, .WhatsApp, .Email {
      max-width: 60px;
      margin-right: 15px;
  }
  
  .my-name-tag {
    font-size: xx-large;
    text-align: center;
    padding-top: 10px;

    }
  
  
  
    .pointer {
      max-width: 50px;
    }
  
    .about-us-pointers {
      display: grid;
      grid-template-columns: 15% 85%;
      align-items: top;
      padding: 10px;
    }
  
    .description-text{
      /* font-family: 'Montserrat', sans-serif; */
      font-size:medium;
      padding-left: 5px;
  }
  
  .contact-item-text {
    font-size: x-large;
    
  }
  
  .about-us-button-container {
      display: grid;
      justify-items: start;
      padding-top: 17px;
      padding-left: 15px;
      padding-right: 15px;
  
      
  }
  
  .map-wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
  
    
  }
  
  .our-office {
    display: flex;
    align-content:center;
    text-align: center;
  }
  
  
  .our-office-pic {
    /* padding-top: 30px; */
    padding-bottom: 40px;
    max-width: 450px;
    text-align: center;
    margin: auto;
    
  }
  
  


  
  
  @media (max-width: 1000px) {

    .our-story {
      max-width:350px;
      display: flex;
    align-content: center;
    margin:auto;
    }

    .about-us-container {
      display: block;
    }

    .about-us-pointers {
      display: grid;
      grid-template-columns: 7% 93%;
    
    }
    .our-office-pic {
      max-width: 250px;
    }
  
    .contact-item img {
      max-width: 50px;
    }
  
    .map-wrapper {
      padding-top: 10px;
      padding-bottom: 40px;
  }
  
    .about-us-button-container {
      display: flex;
      justify-content: center;
      padding-bottom: 50px;
  }
  
  .rammstein {
    display: grid;
    grid-template-rows: 2fr 0.5fr 2fr;
    align-items: top;
    justify-items: center;
}

    .my-picture {
      max-width: 300px;
  }

  .my-name-tag {
    font-size: x-large;
    padding-top: 10px;
  }

}


@media (max-width: 700px) {
.about-us-pointers {
  display: grid;
  grid-template-columns: 10% 90%;
}
}


  @media (max-width: 500px) {
  
    .our-story {
      max-width: 280px;
      display: flex;
    align-content: center;
    margin:auto;
    }
    }
  
  
  @media (max-width: 400px) {
    .my-picture {
      max-width: 250px;
  }
  
  
  .about-us-button {
    width: 200px;
    height: 50px;
  }
  
  .our-story {
    max-width: 260px;
    display: flex;
  align-content: center;
  margin:auto;
  }

  .pointer {
    max-width: 35px;
    padding-top: 5px;
  }


  .about-us-pointers {
    display: grid;
    grid-template-columns: 13% 87%;
  
  }

  
    }