.main-window {
  background-color: #FFFDF1;
  height: 90vh;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  display: grid;
  grid-template-rows: 50% 50%;
  position: relative;
  z-index: -1;
}

.beaver-image-scrolling-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Align to the bottom */
  align-items: center;
  position: relative;
  text-align: center;
}


.beaver-image {
  max-width: 100%;
  max-height: 130%; /* Ensure the image takes the full height of the container */
}

.beaver-image:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px; /* Adjust the height of the line as needed */
  background: #000; /* Adjust the line color */
}


  .company-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  
  .logspot {
    max-width: 90%;
    margin: auto;
    display: block;
  }


  

  /* OUR PARTNERS WE ARE WORKING WITH */

 
  @keyframes slide {
    0%, 100% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-100%);
    }
    50% {
      transform: translateX(-200%);
    }
    75% {
      transform: translateX(-300%);
    }
  }
  
  
  .logos {
    overflow: hidden;
    padding: 22px 0;
    background: white;
    white-space: nowrap;
    position: relative;
    border-bottom: solid #f8f0db 2px;
    border-top: solid #f8f0db 2px;


  }
  
  .logos:before,
  .logos:after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
  }
  
  /* .logos:hover .logos-slide {
    animation-play-state: paused;
  } */
  
  .logos-slide {
    display: inline-block;
    animation: 15s slide infinite linear;
    margin-top: 5px;
  }
  
  .logos-slide img {
    height: 50px;
    margin: 0 40px;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .rotating-logo {
    animation: rotate 5s linear infinite; /* Adjust the duration and animation properties as needed */
  }


   /* Stripes, Stripe1 */

   .stripes-container {
    overflow-x: hidden;
    max-width: 100%;
    height: 500px;
   
   }


   @keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  .stripe1 {
    overflow: hidden;
    padding: 22px 0;
    background: #f04a23;
    white-space: nowrap;
    position: relative;
    bottom: -175px;
    transform: rotate(-10deg) scaleX(1.1); /* Apply the rotation here */
    border: solid black 2px;
  }
  
  .stripe1:before,
  .stripe1:after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
  }
  
  .stripe1-slide {
    display: inline-block;
    animation: 20s slide infinite linear;
  }
  
  .stripe1-slide img {
    height: 50px;
    margin: 0 40px;

  }


   @keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  .stripe2 {
    overflow: hidden;
    padding: 22px 0;
    background: #fcbd2c;
    white-space: nowrap;
    position: relative;
    bottom: -76px;
    transform: rotate(10deg) scaleX(1.1); /* Apply the rotation here */
    border: solid black 2px;
  }
  
  .stripe2:before,
  .stripe2:after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
  }

  .stripe2-slide {
    display: inline-block;
    animation: 20s slide infinite linear;
  }
  
  .stripe2-slide img {
    height: 50px;
    margin: 0 40px;

  }


  
  /* INTRODUCTION TEXT */
  
  .introduction-container {
    max-width: 1440px;
    height: 1000px;
    background-color: #fffdf1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .introduction-text-image {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
 
  }
  
  .introduction-text {
    font-size: x-large;
    color: #000000;
    text-align: center;
    padding-top: 20px; 
    max-width: 80%;

  }
  
  

  /* Card Container */

  .header-card-container{
    max-width: 1440px;
    margin: auto;
    font-size: xx-large;
    font-weight: bolder;
    padding-top: 100px;
    display: grid;
    grid-template-rows: 1fr 1fr;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .header-card-container-text {
    font-size: x-large;
    font-weight: 100;
  }


  .sign-up-process{
    max-width: 550px;
  }

  .number{
    max-width: 60px;
    padding-right: 10px;
  }

  .sticky-container-number {
    font-size: xxx-large;
    font-weight: bolder;
    display: grid;
    grid-template-columns: 10% 90%;
    text-align: left;
    align-items: center;
    padding-bottom: 16px;
  }
  
  .card-container {
   position:relative;
   max-width: 1440px;
   margin: auto;
   padding-left: 20px;
   padding-right: 20px;
  }
  
  .sticky-card-container .section{
    position: sticky;
    top: 0;
    width: 100%;
    height: 100vh;   
    
   
  }

  .sticky-card-container .section:nth-child(1),
  .sticky-card-container .section:nth-child(2),
  .sticky-card-container .section:nth-child(3),
  .sticky-card-container .section:nth-child(4)
  {
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  
  .sticky-card-inner-container-1,
  .sticky-card-inner-container-2,
  .sticky-card-inner-container-3,
  .sticky-card-inner-container-4
  {
    max-width: 1600px;
    height: 600px;
    background-color: white;
    /* background-color: #fcbd2c; */
    border-radius: 20px;
    border: 1px solid;
    padding: 10px;
    box-shadow: 5px 10px;
    display: grid;
    grid-template-columns: 60% 40%;
    align-content: center;
    font-size:x-large;
    align-items: center;

  }
  }
  


.sticky-container-image{
  max-width: 600px;
  padding: 10px;
}

.sticky-card-inner-text {
  padding: 10px;
}

  
  
  @media (max-width: 1100px) {
  
  

    .introduction-container {
      padding-top: 70px;
      height: fit-content;
    
    }

    .introduction-text-image {
      content: url('../images/introduction-text-image-mobile.svg');
      max-width: 90%;
      height: auto;
      margin: auto;
      margin-top: 0;
    }

    .header-card-container {
      max-width: 1440px;
      padding-top: 0px;
  }

  .sticky-container-number {
    grid-template-columns: 10% 90%;
    font-size: xx-large;
}



    }
    
    @media (max-width: 900px) {
     

      
    
      .sticky-card-container .section:nth-child(1),
      .sticky-card-container .section:nth-child(2),
      .sticky-card-container .section:nth-child(3),
      .sticky-card-container .section:nth-child(4){
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        
        .sticky-card-inner-container-1,
        .sticky-card-inner-container-2,
        .sticky-card-inner-container-3,
        .sticky-card-inner-container-4{
          max-width: 1600px;
          height: 650px;
          display: block;
          align-content: center;
          font-size:x-large;
          align-items: center;
      
        }
        }
    
        .sticky-container-image {
          max-width: 400px;
          padding: 0;    
      }

      .sticky-card-inner-container-image {
        display: flex;
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
      }

      .sticky-container-number {
        display: flex;
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
        font-size: xx-large;
        
    }

      }

      @media (max-width: 690px) {
   


        .stripe1 {
          overflow: hidden;
          padding: 6px 0;
          background: #f04a23;
          white-space: nowrap;
          position: relative;
          bottom: -145px;
          transform: rotate(-15deg) scaleX(1.15); /* Apply the rotation here */
          border: solid black 2px;
        }

        .stripe2 {
          overflow: hidden;
          padding: 6px 0;
          background: #fcbd2c;
          white-space: nowrap;
          position: relative;
          bottom: -71px;
          transform: rotate(15deg) scaleX(1.15); /* Apply the rotation here */
          border: solid black 2px;
        }

        .stripes-container {
          height: 390px;
        }

        .introduction-text {
          font-size: 16px;
          color: #000000;
        }
        }

        @media (max-width: 580px) {
    
          .company-logo-container {
            align-items:flex-end;
          }
          
          .logspot {
            margin-bottom: 50px;
        }
          
          .sticky-container-number {
            padding: 0px;
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: x-large;

        }

        .number {
          max-width: 40px;
        }
        
        .sticky-card-inner-text-container {
          font-size: large;
        }

        
          }

          @media (max-width: 480px) {
            
            
            .introduction-text {
              font-size: large;
              max-width: 100%;
            }

            .sticky-container-image {
              max-width: 290px;
              padding: 0;
              /* padding-top: 20px; */
          }

          .sticky-container-number {
            padding: 0px;
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: large;
            justify-content: left;
        }

        .number {
          max-width: 30px;
        }
        
        .sticky-card-inner-text-container {
          font-size: medium;
        }

        .sticky-card-container .section:nth-child(1),
        .sticky-card-container .section:nth-child(2),
        .sticky-card-container .section:nth-child(3),
        .sticky-card-container .section:nth-child(4){
          background-size: cover;
          display: flex;
          justify-content: center;
          align-items: center;
          
          .sticky-card-inner-container-1,
          .sticky-card-inner-container-2,
          .sticky-card-inner-container-3,
          .sticky-card-inner-container-4{
            max-width: 1600px;
            height: 500px;
            display: block;
            align-content: center;
            font-size:x-large;
            align-items: center;
        
          }
          }

          .stripes-container {
            height: 290px;
          }

          .header-card-container {
            max-width: 1440px;
            padding-top: 50px;
            padding-left: 20px;
            padding-right: 20px;
            height: 200px;
          }

          .header-card-container-text {
           font-size: large;
       }

          .logos {
            padding: 10px 0;
          }
   
          .logos-slide img {
             height: 30px;
             margin: 0 20px;
             margin-top: 5px;
          }

          
             }

             @media (max-width: 324px) {
              .sticky-card-container .section {
                position: sticky;
                top: 0;
                width: 100%;
                height: 70vh;
            }

            .sticky-container-image {
              max-width: 220px;
              padding: 0;
              /* padding-top: 20px; */
          }

              }



              @media (max-height: 500px) {
                .sticky-container-image {
                  display: none;
                }
                  
                .sticky-card-container .section:nth-child(1),
                .sticky-card-container .section:nth-child(2),
                .sticky-card-container .section:nth-child(3),
                .sticky-card-container .section:nth-child(4){
                  background-size: cover;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  
                  .sticky-card-inner-container-1,
                  .sticky-card-inner-container-2,
                  .sticky-card-inner-container-3,
                  .sticky-card-inner-container-4{
                    max-width: 1600px;
                    height: 280px;
                    display: block;
                    align-content: center;
                    font-size:x-large;
                    align-items: center;
                  }
              }
            }

              
              
              