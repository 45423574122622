body {
  background-color: #FFFDF1;
  /* font-family: 'Montserrat', sans-serif; */
  font-family: 'Padauk', sans-serif;
                                                                                                          
}

@import url('https://fonts.cdnfonts.com/css/padauk-2');

/* Hide border if the body has a specific class */
/* .no-border .header {
  border-bottom: none;
} */

.hero-container {
  max-width: 1440px;
  margin: auto;
}

.footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding-top: 10px;
  position: relative;
  width: 100%;
  bottom: 0;
  z-index: 2;
  border-top: solid #f8f0db 2px;
  
}




.footer-1, .footer-2, .footer-3, .footer-4 {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  font-size: medium;
  padding-left: 5px;
  text-align: left;
}

li {
  list-style-type: none;
  padding-bottom: 15px;
}

ul {
  padding: 0;
}


.footer-headline {
  padding-bottom: 20px;
  font-weight: bold;
}


.social-images {
  width: 40px;
  height: 40px;
  padding-bottom: 5px;
  padding-right: 10px;
  position: relative;
  bottom: 10px;
  
}

/* .social-list-item {
  display: flex;
  align-content: end;
  text-align: left;
  padding-bottom: 5px;
} */


a {
  color: black !important;  
  text-decoration: none; 
}

.social-text {
  /* vertical-align: middle;  */
  display: inline-block; 
  text-align: top;
}



/* .social-list-item {
  display: inline-block;
  margin-right: 10px; 
} */

.social-images {
  vertical-align: top; 
  margin-right: 5px; 
}

/* Responsive design max-width: 685px */
@media (max-width: 685px) {
  .social-text {
    display: none; /* Hide the social text */
  }


  .social-images {
    vertical-align: top; 
    margin-right: 0; 
  }

  .footer-1, .footer-2, .footer-3, .footer-4 {
    font-size: small;
}
}

@media (max-width: 480px) {

.footer-1, .footer-2, .footer-3, .footer-4 {
  padding-left: 15px;
}
}
