.for-talents-page-container{
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.talents-header {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  text-align: center; /* Center text within the container */
  padding-bottom: 5 0px;
}

.header-content {
  display: flex;
  align-items: center;
}

.centered-content {
  margin-top: 10px;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.join-logspot-text {
  padding-top: 20px;
    text-align: center;
    font-size: xx-large;
    font-weight: bold;
    -webkit-text-stroke: 1px black;
}

.logger-image {
  max-width: 600px;
  display: flex;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  
}

.logger-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  /* Other styles for your container */
}

.job-application-form-container {
  max-width: 100%; /* Adjust the width as needed */
  margin: auto;
  background-color: #fffdf1; /* Background color for the form container */
  /* border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  max-width: 600px;
  padding: 20px;

}

.job-application-form-container h1 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.job-application-form-container form {
  display: grid;
  gap: 10px;
}

.job-application-form-container label {
  /* font-weight: bold; */
}

.job-application-form-container input[type="text"],
.job-application-form-container input[type="tel"],
.job-application-form-container input[type="email"],
.job-application-form-container input[type="date"],
.job-application-form-container input[type="file"],
.job-application-form-container select {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.job-application-form-container input[type="radio"],
.job-application-form-container input[type="checkbox"] {
  margin-right: 5px;
}

.hint {
  font-size: 0.8em;
  color: #888; /* Use a muted color for hints */
  margin-left: 5px; /* Add some spacing between the input and the hint */
}


.job-application-form-container button {
  background-color: #fcbd2c;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  border: rgb(195, 183, 183) solid 1px;

}

.job-application-form-container button:hover {
  background-color: #e5ac28; 
}

.talents-page-description {
display: flex;
max-width: 100%;
font-size: large;
}

.beaver45 {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 170px;
  height: auto;
  z-index: 10;
  
}

.blue-link {
  color: blue !important;
}

.login-talent-page {
  padding: 10px;
}

.success-notification {
  /* padding-left: 10px; */
  display: flex;
  justify-content: center;
  padding: 5px;
}

/* Responsive design max-width: 1212px */

.form-group {
max-width: 600px;
}

@media (max-width: 1100px) {
  .beaver45 {
    width: 150px;
  }

  .logger-image {
    max-width: 500px;
  }
}

/* Responsive design max-width: 900px */

@media (max-width: 900px) {
  .beaver45 {
    width: 50px;
  }

  .for-talents-page-container {
    display: block;
}

.job-application-form-container form {
  display: grid;
  gap: 10px;
  padding: 10px;
}

.join-logspot-text {
  font-size: x-large;
  padding-top: 40px;

}

.LogspotLogoNew{
  max-width: 30px;
}
}

@media (max-width: 500px) {
  .logger-image {
    max-width: 400px;
  }
}

@media (max-width: 400px) {
  .logger-image {
    max-width: 290px;
  }
}