.for-employers-h1 {
    padding-top: 20px;
    text-align: center;
    font-size: xx-large;
    font-weight: bold;
    -webkit-text-stroke: 1px black;
}

.ForEmployerPageHeader{
    margin-top: 10px;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;

}

.ForEmployerPageForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 606px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;

  

  }

 
  
  .ForEmployerPageForm label {
    margin-bottom: 10px;
   

  }
  
  .ForEmployerPageForm input,
  .ForEmployerPageForm textarea {
    width: 600px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin: 4px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .ForEmployerPageForm button {
    background-color: #fcbd2c;
    color: #fff;
    padding: 15px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
    border: rgb(195, 183, 183) solid 1px;
    

  }

  .ForEmployerPageForm button:hover {
    background-color: #e5ac28; 
  }

  .for-employers-page-layout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 20px;
  }


  .beaver-head {
    max-width: 500px;
    display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  /* Other styles for your container */
    padding-left: 10px;
    padding-right: 10px;
    /* padding-top: 10px; */

  }

/* Responsive design max-width: 1200px */


@media (max-width: 1200px) {
  .ForEmployerPageForm input,
  .ForEmployerPageForm textarea
  {
    max-width: 500px;
   
  }



  .ForEmployerPageForm {
    max-width: 500px;


  }
}

/* Responsive design max-width: 1024px */

@media (max-width: 1024px) {
  .ForEmployerPageForm input,
  .ForEmployerPageForm textarea
  {
    max-width: 400px;

  }

  .ForEmployerPageForm 
  {
   
    max-width: 400px;
   

  }
}

.beaver-head {
  max-width: 400px;
  display: flex;
  margin:auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;

}

/* Responsive design max-width: 859px */

@media (max-width: 859px) {

  .ForEmployerPageForm button {
    padding: 8px 20px;

  }


  .ForEmployerPageForm input,
  .ForEmployerPageForm textarea
  {
    max-width: 300px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin: 4px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  
}


  .ForEmployerPageForm 
  {
    
    max-width: 300px;
    

  }



.beaver-head {
  max-width: 400px;
  display: flex;
  margin:auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;

}

  .for-employers-h1 {
    font-size:x-large;
    padding-top: 40px;
  
  }


  .ForEmployerPageHeader {
    text-align: center;
}
}


/* Responsive design max-width: 600px */

@media (max-width: 700px) {
.ForEmployerPageForm input,
.ForEmployerPageForm textarea
{
  max-width: 400px;

}


.ForEmployerPageForm 
{
  margin: auto;
  max-width: 400px;
 

}


.for-employers-page-layout {
  display: block;
  align-items: center;

}



}

/* Responsive design max-width: 430px */

@media (max-width: 430px) {
  .ForEmployerPageForm input,
  .ForEmployerPageForm textarea
  {
    max-width: 300px;
      padding-top: 8px;
      padding-bottom: 8px;
      margin: 4px 0;
      border: 1px solid #ccc;
      border-radius: 4px;
  }
  

  .ForEmployerPageForm {
  
    max-width: 300px;
  
  }
  
  

  .for-employers-page-layout {
    display: block;
    align-items: center;
  }
  }

/* Responsive design max-width: 340px */

@media (max-width: 340px) {
  .ForEmployerPageForm input,
  .ForEmployerPageForm textarea
  {
    max-width: 250px;
      padding-top: 8px;
      padding-bottom: 8px;
      margin: 4px 0;
      border: 1px solid #ccc;
      border-radius: 4px;
  }
  
  
  .ForEmployerPageForm 
  {
    max-width: 250px;
    
  }
  
  
  .for-employers-page-layout {
    display: block;
    align-items: center;
  }
  
    }