/* ContactForm.css */

/* Style for the modal overlay */
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

  }
  
  /* Style for the modal content */
  .modal-content {
    background-color: #FFFDF1;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    text-align: center; /* Center-align the content */
    border-radius: 20px;

  }
  
  /* Style for form elements */
  .modal-content form label {
    display: block;
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; */
    margin-bottom: 10px;
    padding: 5px;
    text-align: center;
    margin: auto;
  }
  
  /* Style for the textarea input */
  .modal-content form textarea {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
  }
  
  /* Style for the submit button */
  .modal-content form button {
    background-color: #fcbd2c;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    border: rgb(195, 183, 183) solid 1px;

  }
  
  /* Hover effect for the submit button */
  .modal-content form button:hover {
    background-color: #e5ac28; 
  }

  /* Style for the close button */
.close-button {
  position: absolute;
  top: 50px;
  right: 50px;
  background-color: transparent;
  color: #999;
  border: none;
  font-size: 20px;
  cursor: pointer;
  outline: none;
}

/* Style for the close button on hover */
.close-button:hover {
  color: #333;
}

.error-message {
    color: red; /* Set the color to red */
    margin-top: 10px; /* Adjust the margin as needed */
    font-weight: bold; /* Make the text bold */
  }

  .about-us-button {
    background-color: #fcbd2c;
    color: black;
    /* color: white; */

    font-weight: 700;
    border-radius: 20px;
    padding: 10px 20px;
    margin-bottom: 5px;
    border: 2px solid #fcbd2c;
    text-decoration: none;
    font-size:x-large;
    display: inline-block;
    text-align: center;
    width: 240px;
    height: 60px;
    border: solid 2px black;  
    box-shadow: 2px 7px  black;
}

.about-us-button:hover {
    background-color: transparent;
    /* color: #fcbd2c; */
    color: black;
    cursor: pointer;
    background-color: white;
}

/* .text-contact-us {
  padding-right: 5px;
} */

.contact-us-form-header {
  font-size: x-large;
}